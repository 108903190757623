import gql from "graphql-tag";



//HOME BANNER
const BANNER_ITEMS = gql `
fragment Banner1 on HomePageContentDynamicZone {
  ... on ComponentGlobalBanner {
    __typename
    id
    title
    items {
      id
      title
      subTitle
      image {
        data {
          id
          attributes {
            url
          }
        }
      }
      image_mb {
        data {
          id
          attributes {
            url
          }
        }
      }
      link
      link_text
      type
      video_url
    }
  }
}
`

const HOME_SMALL_CATEGORIES = gql `
fragment HomeSmallCategories on HomePageContentDynamicZone {
  ... on ComponentGlobalHomeSmallCategories {
    __typename
    id
    title
    items {
      id
      title
      subTitle
      image {
        data {
          id
          attributes {
            url
          }
        }
      }
      link
    }
  }
}
`

const IMAGE_RIGHT_CENTER = gql `
fragment ImageRightCenter on HomePageContentDynamicZone {
  ... on ComponentGlobalImageRightCenter {
    __typename
    id
    title
    image_center {
      data {
        attributes {
          url
        }
      }
    }
    image_right {
      data {
        attributes {
          url
        }
      }
    }
    cta {
      text
      url
    }
  }
}

`

const OUR_SELECTION = gql `fragment OurSelection on HomePageContentDynamicZone {
  ... on ComponentGlobalOurSelection {
    __typename
    id
    title
    subTitle
    cta {
      text
      url
    }
  }
}`

const THE_SHOPS = gql `fragment TheShops on HomePageContentDynamicZone {
  ... on ComponentGlobalTheShops {
    __typename
    id
    title
    discription
    banner {
      title
      subTitle
      image {
        data {
          attributes {
            url
          }
        }
      }
      link
      link_text
    }
    cta {
      text
      url
    }
  }
}`

const SIMPLE_BLOC = gql `fragment SimpleBloc1 on HomePageContentDynamicZone {
  ... on ComponentGlobalSimpleBloc {
    __typename
    id
    title
    subTitle
    discription
    cta {
      text
      url
    }
  }
}`


export const HomeQuery = gql `
    ${BANNER_ITEMS}
    ${HOME_SMALL_CATEGORIES}
    ${IMAGE_RIGHT_CENTER}
    ${OUR_SELECTION}
    ${THE_SHOPS}
    ${SIMPLE_BLOC}
    query getHomePage($lang:I18NLocaleCode) {
      homePage(locale:$lang) {
        data {
          id
          attributes {
            title
            content {
              ...Banner1
              ...HomeSmallCategories
              ...ImageRightCenter
              ...OurSelection
              ...TheShops
              ...SimpleBloc1
            }
          }
        }
      }
    }
  `;
